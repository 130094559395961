import React, { useState } from 'react';

import {
    Avatar,
    AppBar,
    Box,
    Button,
    Grid,
    Toolbar,
    IconButton,
    Menu,
    MenuItem,
    createTheme,
    Typography,
} from "@mui/material";
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import { ThemeProvider } from '@mui/system';

export default function (props: any) {

    //@ts-ignore
    const { session } = props;
    const [anchorLogout, setAnchorLogout] = useState(null);
    const isOpenLogout = Boolean(anchorLogout);

    const handleLogoutClick = (event: any) => {
        setAnchorLogout(event.currentTarget);
    };
    const handleLogoutClose = () => {
        setAnchorLogout(null);
    };
    const handleLogout = () => {
        window.location.href = '/logout';
    };

    const theme = createTheme({
        typography: {
            subtitle1: {
                fontSize: 12,
            },
            body1: {
                fontWeight: 500,
            },
            button: {
                fontStyle: 'italic',
            },
        },
    });

    return (
        <>
            <ThemeProvider theme={theme}>
                <Box sx={{ flexGrow: 1 }}>
                    <AppBar position="fixed">
                        <Toolbar>
                            <Grid
                                container
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                spacing={0.5}
                            >
                                <Grid item xs={1}>
                                    <IconButton
                                        size="large"
                                        edge="start"
                                        color="inherit"
                                        href='/menu'
                                    >
                                        <MenuIcon />
                                    </IconButton>
                                </Grid>
                                <Grid item xs={1}>
                                    <Avatar alt="Remy Sharp" src="../statics/Icon100.JPG" />
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography noWrap component='p'>氏名：{session.userName}</Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <IconButton
                                        size="large"
                                        edge="start"
                                        color="inherit"
                                        onClick={handleLogoutClick}
                                    >
                                        <LogoutIcon />
                                    </IconButton>
                                    <Menu
                                        id="logout"
                                        anchorEl={anchorLogout}
                                        open={isOpenLogout}
                                        onClose={handleLogoutClose}
                                    >
                                        <MenuItem onClick={handleLogout}>ログアウト</MenuItem>
                                    </Menu>
                                </Grid>
                            </Grid>
                        </Toolbar>
                    </AppBar>
                </Box>
            </ThemeProvider>
        </>
    )
}

