import React, { FC, ReactElement } from "react";
import { Box, Container, createTheme, Divider, Grid, styled, Typography } from "@mui/material";

const theme = createTheme({
    palette: {
        mode: 'light'
    },
})

const FooterDivider = styled(Divider)(({ theme:any }) => ({
    divier: {
        bordercolor: theme.palette.common.black,
    },
}));


export const Footer: FC = (): ReactElement => {
    return (
        <Box
            sx={{
                width: "100%",
                height: "auto",
                backgroundColor: "white",
                paddingTop: "1rem",
                paddingBottom: "1rem",
            }}
        >
            <Container maxWidth="lg">
                <Divider sx={{ bgcolor: "lightgray", borderRightWidth: 5 }}/>
                <Grid container direction="column" alignItems="center">
                    <Grid item xs={12}>
                        <Typography color="black" variant="caption" sx={{color:"silver"}}>
                            （学）静岡自動車学園
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default Footer;