import humps from 'humps'
import { csrfToken } from '../index.js';

let session;

try {
    //fetch前のawaitは外さないこと
    session = await fetch('/ajax/get/session_data',
        {
            method: 'POST',
            headers: {'X-CSRFToken': csrfToken, 'Content-type':'application/json'},
            body: JSON.stringify({})
        })
        .then((response) => {
            if (!response.ok) {
                return Promise.reject(response.json());
            } else {
                return response.json();
            }
        })
        .then((data)=>{
            const re_data = JSON.parse(data);
            const filterdSessionData = {}
            Object.keys(re_data).filter((key)=>{
                if(!key.startsWith('_')){
                    filterdSessionData[key] = re_data[key]
                }
            })
            const sessionData = humps.camelizeKeys(filterdSessionData);
            return sessionData;
        })
    } catch (error: any) {
        let errorMessage:any;
        if (error instanceof Error) {
            errorMessage = error;
        } else {
            errorMessage = await error.then((value: any) => {
                return Promise.resolve(value?.message ?? error);
            });
        }
    }

    export default session;