import { parse, format, parseISO } from 'date-fns';
import ja from 'date-fns/locale/ja'
import { get_json_data, } from '../index.js';
import humps from 'humps'

//  勤務更新ができる可否判定
    // @ts-ignore
const canUpsert = async ({codeBumon, userId, date}):Promise<boolean> => {
    try {
        const dateStart = format(date, 'yyyy-MM-1', { locale: ja });
        let canUpsert = true;
        let param = {
            'codeBumon': codeBumon,
            'userId': userId,
            'date': dateStart
        }

        const paramDecamelized = Object.fromEntries(
            Object.entries(param).map(([ key, val ]) => [ humps.decamelize(key), val ]));

        // pythonからの戻り値はキャメル
        const { is_unlocked}       = await get_json_data('/ajax/get/approval/is_unlocked', paramDecamelized);
        const { is_closed_user }   = await get_json_data('/ajax/get/approval/is_closed_user', paramDecamelized);
        const { is_closed_upper }  = await get_json_data('/ajax/get/approval/is_closed_upper', paramDecamelized);
        const { is_closed_bumon }  = await get_json_data('/ajax/get/approval/is_closed_bumon', paramDecamelized);
        const { is_closed_gakuen } = await get_json_data('/ajax/get/approval/is_closed_gakuen', paramDecamelized);

        console.log("koko", is_unlocked)

        const isRecordStatus = {
            "isClosedGakuen": false,
            "isClosedBumon" : false,
            "isClosedUpper" : false,
            "isClosedUser"  : false,
        }

        if(!is_unlocked){
            isRecordStatus.isClosedUser   = is_closed_user;
            isRecordStatus.isClosedUpper  = is_closed_upper;
            isRecordStatus.isClosedBumon  = is_closed_bumon;
            isRecordStatus.isClosedGakuen = is_closed_gakuen;

            const is_closed = Object.entries(isRecordStatus).map(([key, value]) => { return value }).some((element) => element === true)
            canUpsert = is_closed == false
        }

        return Promise.resolve(canUpsert);
    }
    catch(error){
        return Promise.reject(error);
    }
}

export default canUpsert