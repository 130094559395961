import React, { useState, useRef } from 'react';
import { createRoot } from 'react-dom/client';
import {
    Alert,
    Avatar,
    Box,
    Button,
    Grid,
    Paper,
    Snackbar,
    TextField,
    Typography
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { teal } from "@mui/material/colors";
import { csrfToken } from '../index.js';

const Login = () => {

    const inputMobileEMail = useRef<HTMLInputElement>(null);
    const inputPassword = useRef<HTMLInputElement>(null);

    const [errorMessage, setErrorMessage] = useState<string>('');
    const [openErrorMessage, setErrorMessageOpen] = useState(false);

    const handleErrorMessage = (message: string) => {
        setErrorMessage(message);
        setErrorMessageOpen(true);
    }
    const handleErrorMessageClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setErrorMessageOpen(false);
    };

    const handleLoginClick = async () => {
        const submitParam: any = {
            'id': inputMobileEMail.current!.value,
            'password': inputPassword.current!.value
        }

        if (Object.entries(submitParam).map(([_, value]) => value).some((value) => !Boolean(value))) {
            handleErrorMessage('メールアドレス又はパスワードが未入力です');
            return;
        }
        // Object.entries(submitParam).map((_, value) => value)

        try {
            //fetch前のawaitは外さないこと
            const re_data = await fetch('/ajax/login/email',
                {
                    method: 'POST',
                    headers: { 'X-CSRFToken': csrfToken, 'Content-Type': 'application/json' },
                    body: JSON.stringify(submitParam)
                })
                .then((response) => {
                    if (!response.ok) {
                        return Promise.reject(response.json());
                    } else {
                        location.href = '/menu';
                    }
                })
                .then((data) => {
                    let re_value;
                    if (!data) {
                        re_value = '';
                    } else {
                        re_value = JSON.parse(data);
                    }
                    return Promise.resolve(re_value);
                })
            return Promise.resolve(re_data);
        } catch (error: any) {
            let errorMessage;
            if (error instanceof Error) {
                errorMessage = error;
            } else {
                errorMessage = await error.then((value: any) => {
                    return Promise.resolve(value?.message ?? error);
                });
            }
            handleErrorMessage(errorMessage);
        }
    }

    return (
        <Grid>
            <Paper
                elevation={3}
                sx={{
                    p: 4,
                    height: "70vh",
                    width: "230px",
                    m: "30px auto"
                }}
            >
                <Grid
                    container
                    direction="column"
                    justifyContent="flex-start" //多分、デフォルトflex-startなので省略できる。
                    alignItems="center"
                >
                    <Avatar alt="Remy Sharp" src="../statics/Icon100.JPG" />
                    <Typography variant={"h5"} sx={{ mt: "30px" }}>
                        AnotherStaff
                    </Typography>
                    <Typography variant={"h5"} sx={{ mt: "0px" }}>
                        ログイン
                    </Typography>
                    <Snackbar
                        open={openErrorMessage}
                        autoHideDuration={3000}
                        onClose={handleErrorMessageClose}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <Alert onClose={handleErrorMessageClose} severity="error" sx={{ width: '100%' }}>
                            {errorMessage}
                        </Alert>
                    </Snackbar>
                </Grid>
                <TextField label="登録済みメールアドレス" variant="standard" fullWidth required inputRef={inputMobileEMail} />
                <TextField
                    type="password"
                    label="パスワード"
                    variant="standard"
                    fullWidth
                    required
                    inputRef={inputPassword}
                />
                <Box mt={3}>
                    <Button color="primary" variant="contained" fullWidth onClick={handleLoginClick}>
                        サインイン
                    </Button>
                </Box>
                <Box mt={5}>
                    <Grid
                        container
                        direction="column"
                        justifyContent="flex-start" //多分、デフォルトflex-startなので省略できる。
                        alignItems="center"
                    >
                        <Typography variant='caption' align="center" >
                            ご利用可能時間：6:00～22:00
                        </Typography>
                        <Typography variant='caption' align="center" >
                            （学）静岡自動車学園
                        </Typography>
                    </Grid>
                </Box>
            </Paper>
        </Grid>
    );
};

const app = createRoot(document.getElementById('app')!);
app.render(<Login />);
