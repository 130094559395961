import { createTheme } from '@mui/material';
import { CSSProperties } from 'react';
import { csrfToken } from '../index.js';

const get_json_data = async (path:string, param:{})=>{
    try{
        const re_data = await fetch(path,
            {
                method  : 'POST',
                headers : {'X-CSRFToken': csrfToken, 'Content-Type': 'application/json'},
                body    : JSON.stringify(param)
            })
            .then((response) => {
                if (!response.ok) {
                    return Promise.reject(response.json());
                }
                const contentType = response.headers.get('content-type');
                if (!contentType || !contentType.includes('application/json')) {
                    // no hit
                    return Promise.resolve();
                }
                return response.json();
            })
            .then((data)=>{
                let re_value;
                if(!data){
                    re_value = '';
                }else{
                    re_value = JSON.parse(data);
                }
                return Promise.resolve(re_value);
            })
        return Promise.resolve(re_data);
    }catch(error:any){
        const types:String[] = Object.prototype.toString.call(error).split(" ").map((value)=> value.replace(/\[|\]/,""));
        let error_message;
        switch(types[1]){
            case "Error":
                error_message = error.message;
                break;
            case "String":
                error_message = error;
                break;
            case "Promise":
                error_message = await error.then((value:any)=>{
                    return Promise.resolve(value?.message ?? error);
                });
            break;
        }
    }
}

const cssLoading: CSSProperties = {
    display: "block",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
};

const defaultTheme = createTheme({
    palette: {
        mode: 'light'
    },
})

export {get_json_data, cssLoading, defaultTheme}
